<template>
    <aside class="my-account-sidebar">
        <!-- Menu -->
        <ul class="mb-3">
            <li class="d-flex align-items-center">
                <span>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 14 14"
                    >
                        <path
                            d="M3018-5918a2,2,0,0,1-2-2v-2a2,2,0,0,1,2-2h2a2,2,0,0,1,2,2v2a2,2,0,0,1-2,2Zm-8,0a2,2,0,0,1-2-2v-2a2,2,0,0,1,2-2h2a2,2,0,0,1,2,2v2a2,2,0,0,1-2,2Zm8-8a2,2,0,0,1-2-2v-2a2,2,0,0,1,2-2h2a2,2,0,0,1,2,2v2a2,2,0,0,1-2,2Zm-8,0a2,2,0,0,1-2-2v-2a2,2,0,0,1,2-2h2a2,2,0,0,1,2,2v2a2,2,0,0,1-2,2Z"
                            transform="translate(-3008 5932)"
                        />
                    </svg>
                </span>
                <span class="mx-3 font-weight-600">Menu</span>
            </li>
        </ul>
        <ul class="mb-4 d-flex flex-lg-column flex-wrap">
            <li class="d-flex align-items-center link">
                <router-link to="/my-account" class="py-1">
                    <span>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 14 14"
                        >
                            <path
                                class="fill"
                                d="M7,7.875A3.938,3.938,0,1,0,3.063,3.938,3.939,3.939,0,0,0,7,7.875Zm3.5.875H8.993a4.76,4.76,0,0,1-3.987,0H3.5A3.5,3.5,0,0,0,0,12.25v.438A1.313,1.313,0,0,0,1.313,14H12.688A1.313,1.313,0,0,0,14,12.688V12.25A3.5,3.5,0,0,0,10.5,8.75Z"
                                opacity="0.2"
                            />
                        </svg>
                    </span>
                    <span class="mx-3">My Profile</span>
                </router-link>
            </li>
            <li class="d-flex align-items-center link">
                <router-link to="/my-account/favorites" class="py-1">
                    <span>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.5"
                            height="14"
                            viewBox="0 0 17.5 14"
                        >
                            <path
                                class="fill"
                                d="M16.625,0H4.375A.875.875,0,0,0,3.5.875V3.5H7.875V1.75h5.25V10.5h3.5a.875.875,0,0,0,.875-.875V.875A.875.875,0,0,0,16.625,0ZM6.344,2.816a.246.246,0,0,1-.246.246h-.82a.246.246,0,0,1-.246-.246V2a.246.246,0,0,1,.246-.246H6.1A.246.246,0,0,1,6.344,2ZM15.969,8.5a.246.246,0,0,1-.246.246H14.9a.246.246,0,0,1-.246-.246v-.82a.246.246,0,0,1,.246-.246h.82a.246.246,0,0,1,.246.246Zm0-2.844a.246.246,0,0,1-.246.246H14.9a.246.246,0,0,1-.246-.246V4.84a.246.246,0,0,1,.246-.246h.82a.246.246,0,0,1,.246.246Zm0-2.844a.246.246,0,0,1-.246.246H14.9a.246.246,0,0,1-.246-.246V2A.246.246,0,0,1,14.9,1.75h.82A.246.246,0,0,1,15.969,2ZM11.375,4.375H.875A.875.875,0,0,0,0,5.25v7.875A.875.875,0,0,0,.875,14h10.5a.875.875,0,0,0,.875-.875V5.25A.875.875,0,0,0,11.375,4.375Zm-8.75,1.75A.875.875,0,1,1,1.75,7a.875.875,0,0,1,.875-.875ZM10.5,12.25H1.75v-.875L3.5,9.625l.875.875L7.875,7,10.5,9.625Z"
                                opacity="0.2"
                            />
                        </svg>
                    </span>
                    <span class="mx-3">My Favorites</span>
                </router-link>
            </li>
            <li class="d-flex align-items-center link">
                <router-link to="/my-account/my-modules" class="py-1">
                    <span>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.5"
                            height="14"
                            viewBox="0 0 17.5 14"
                        >
                            <path
                                class="fill"
                                d="M16.625,0H4.375A.875.875,0,0,0,3.5.875V3.5H7.875V1.75h5.25V10.5h3.5a.875.875,0,0,0,.875-.875V.875A.875.875,0,0,0,16.625,0ZM6.344,2.816a.246.246,0,0,1-.246.246h-.82a.246.246,0,0,1-.246-.246V2a.246.246,0,0,1,.246-.246H6.1A.246.246,0,0,1,6.344,2ZM15.969,8.5a.246.246,0,0,1-.246.246H14.9a.246.246,0,0,1-.246-.246v-.82a.246.246,0,0,1,.246-.246h.82a.246.246,0,0,1,.246.246Zm0-2.844a.246.246,0,0,1-.246.246H14.9a.246.246,0,0,1-.246-.246V4.84a.246.246,0,0,1,.246-.246h.82a.246.246,0,0,1,.246.246Zm0-2.844a.246.246,0,0,1-.246.246H14.9a.246.246,0,0,1-.246-.246V2A.246.246,0,0,1,14.9,1.75h.82A.246.246,0,0,1,15.969,2ZM11.375,4.375H.875A.875.875,0,0,0,0,5.25v7.875A.875.875,0,0,0,.875,14h10.5a.875.875,0,0,0,.875-.875V5.25A.875.875,0,0,0,11.375,4.375Zm-8.75,1.75A.875.875,0,1,1,1.75,7a.875.875,0,0,1,.875-.875ZM10.5,12.25H1.75v-.875L3.5,9.625l.875.875L7.875,7,10.5,9.625Z"
                                opacity="0.2"
                            />
                        </svg>
                    </span>
                    <span class="mx-3">My Modules</span>
                </router-link>
            </li>
            <li class="d-flex align-items-center link">
                <router-link to="/my-account/cme-accredited" class="py-1">
                    <span>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="17"
                            viewBox="0 0 14.05 14"
                        >
                            <g transform="translate(-54.701)">
                                <g transform="translate(54.701 0)">
                                    <g transform="translate(0 0)">
                                        <path
                                            class="fill"
                                            d="M62.447.251a1.159,1.159,0,0,0,1.064.2,1.159,1.159,0,0,1,1.343.52,1.159,1.159,0,0,0,.92.57,1.159,1.159,0,0,1,1.065.971,1.159,1.159,0,0,0,.652.864,1.159,1.159,0,0,1,.642,1.29,1.159,1.159,0,0,0,.3,1.041,1.159,1.159,0,0,1,.133,1.435,1.159,1.159,0,0,0-.1,1.078A1.159,1.159,0,0,1,68.069,9.6a1.159,1.159,0,0,0-.483.969,1.159,1.159,0,0,1-.868,1.15,1.159,1.159,0,0,0-.8.729,1.159,1.159,0,0,1-1.225.758,1.159,1.159,0,0,0-1.009.391,1.159,1.159,0,0,1-1.416.265,1.159,1.159,0,0,0-1.082,0,1.159,1.159,0,0,1-1.416-.265,1.159,1.159,0,0,0-1.009-.391,1.159,1.159,0,0,1-1.225-.758,1.159,1.159,0,0,0-.8-.729,1.159,1.159,0,0,1-.868-1.15,1.159,1.159,0,0,0-.483-.969,1.159,1.159,0,0,1-.394-1.386,1.159,1.159,0,0,0-.1-1.078,1.159,1.159,0,0,1,.133-1.435,1.159,1.159,0,0,0,.3-1.041,1.159,1.159,0,0,1,.642-1.29,1.159,1.159,0,0,0,.652-.864,1.159,1.159,0,0,1,1.065-.971A1.159,1.159,0,0,0,58.6.97,1.159,1.159,0,0,1,59.942.45a1.159,1.159,0,0,0,1.064-.2,1.159,1.159,0,0,1,1.441,0Z"
                                            transform="translate(-54.701 0)"
                                            opacity="0.2"
                                        />
                                        <g transform="translate(1.912 1.887)">
                                            <path
                                                d="M117.764,59.8a5.378,5.378,0,0,0-3.643-5.091,5.113,5.113,0,0,0,0,10.181A5.378,5.378,0,0,0,117.764,59.8Z"
                                                transform="translate(-109.489 -54.688)"
                                                fill="#e5e5e5"
                                                class="fill"
                                            />
                                            <path
                                                d="M224.509,54.07a5.129,5.129,0,0,0-1.139.127,5.114,5.114,0,0,1,0,9.971,5.113,5.113,0,1,0,1.139-10.1Z"
                                                transform="translate(-219.396 -54.07)"
                                                fill="#e5e5e5"
                                                class="fill"
                                            />
                                            <text
                                                transform="translate(2.003 6.248)"
                                                fill="#afafaf"
                                                class="fill-white"
                                                font-size="3"
                                                font-family="NotoSans-Bold, Noto Sans"
                                                font-weight="700"
                                            >
                                                <tspan x="0" y="0">CME</tspan>
                                                <tspan y="0">‏</tspan>
                                            </text>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </span>
                    <span class="mx-3">My CME Accredited ‏</span>
                </router-link>
            </li>
            <li class="d-flex align-items-center link">
                <router-link to="/my-account/change-password" class="py-1">
                    <span>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="17"
                            viewBox="0 0 12.25 14"
                        >
                            <path
                                class="fill"
                                d="M10.938,6.125h-.656V4.156a4.156,4.156,0,0,0-8.312,0V6.125H1.313A1.313,1.313,0,0,0,0,7.438v5.25A1.313,1.313,0,0,0,1.313,14h9.625a1.313,1.313,0,0,0,1.313-1.312V7.438A1.313,1.313,0,0,0,10.938,6.125Zm-2.844,0H4.156V4.156a1.969,1.969,0,0,1,3.938,0Z"
                                opacity="0.2"
                            />
                        </svg>
                    </span>
                    <span class="mx-3">Change Password</span>
                </router-link>
            </li>
        </ul>

        <!-- Tasks to complete -->
        <ul class="mb-4">
            <li class="d-flex align-items-center">
                <span>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="16"
                        viewBox="0 0 17.326 14.62"
                    >
                        <path
                            d="M4.724,2.367a.406.406,0,0,0-.575,0L1.994,4.51l-.768-.749a.406.406,0,0,0-.575,0l-.531.532a.406.406,0,0,0,0,.575l1.61,1.6a.432.432,0,0,0,.6,0l.528-.529L5.3,3.5a.409.409,0,0,0,0-.575Zm0,5.387a.406.406,0,0,0-.575,0L1.994,9.911l-.768-.748a.406.406,0,0,0-.575,0l-.531.531a.406.406,0,0,0,0,.575l1.606,1.607a.432.432,0,0,0,.6,0l.531-.531L5.3,8.9a.406.406,0,0,0,0-.572ZM2.166,13.619a1.624,1.624,0,1,0,0,3.249,1.624,1.624,0,1,0,0-3.249Zm14.619.541H7.039A.541.541,0,0,0,6.5,14.7v1.083a.541.541,0,0,0,.541.541h9.746a.541.541,0,0,0,.541-.541V14.7A.541.541,0,0,0,16.785,14.161Zm0-10.829H7.039a.541.541,0,0,0-.541.541V4.956a.541.541,0,0,0,.541.541h9.746a.541.541,0,0,0,.541-.541V3.873A.541.541,0,0,0,16.785,3.332Zm0,5.414H7.039a.541.541,0,0,0-.541.541v1.083a.541.541,0,0,0,.541.541h9.746a.541.541,0,0,0,.541-.541V9.288A.541.541,0,0,0,16.785,8.746Z"
                            transform="translate(0 -2.248)"
                        />
                    </svg>
                </span>
                <span class="mx-3 font-weight-600">Tasks to complete</span>
            </li>
        </ul>
        <ul class="d-flex flex-lg-column flex-wrap flex-lg-nowrap">
            <li class="d-flex align-items-center link mb-2">
                <router-link to="/my-account/modules" class="py-1">
                    <span class="tasks-count">{{ modulesTaskCount }}</span>
                    <span class="mx-3">Modules</span>
                </router-link>
            </li>
            <li class="d-flex align-items-center mx-4 mx-lg-0 link mb-2">
                <router-link to="/my-account/quiz" class="py-1">
                    <span class="tasks-count"> {{ quizCount }} </span>
                    <span class="mx-3">Quiz</span>
                </router-link>
            </li>
            <li class="d-flex align-items-center link mb-2">
                <router-link to="/my-account/recently-visited" class="py-1">
                    <span class="tasks-count"> {{ modulesVisitedCount }} </span>
                    <span class="mx-3">Recently visited</span>
                </router-link>
            </li>
        </ul>

        <div class="d-lg-none line my-3"></div>
    </aside>
</template>

<script>
import { 
    recentlyVisitedCount, 
    moduleCount 
} from "@/API/my-account";

export default {
    name: "MyAccountSidebar",
    data() {
        return {
            modulesVisitedCount: 0,
            quizCount: 0,
            modulesTaskCount: 0
        }
    },
    async mounted() {
        await recentlyVisitedCount().then(res => {
            if (res.status == 200) {
                const data = res.data?.data;
                // console.log('Recently visited count', data);
                this.modulesVisitedCount = data || 0;
            }
        });

        moduleCount().then(res => {
            if (res.status == 200) {
                const data = res.data?.data;
                // console.log('Module Count', data);
                this.modulesTaskCount = data || 0;
            }
        });
    }
};
</script>
